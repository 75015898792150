import React from 'react';
import LandingPage from "./sites/LandingPage"

function App() {
  return (
    <div className="App" >
      <LandingPage/>
    </div>
  );
}

export default App;

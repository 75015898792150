import React from 'react'
import styled from 'styled-components'
import Card from './Card'


const ProjectsContainer = () => {
    return (
        <Container>
           <Card/>
        </Container>
    )
}

export default ProjectsContainer

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    margin-top: 50px;
    justify-content: center;
`
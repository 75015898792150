import React from 'react';
import styled from 'styled-components';

const AboutMeText = () => {
    return (
            <TextContainer>
                <H3>
                    Disco Monarch is a young DJ talent trying to resurrect the global techno scene. Starting out from Frankfurt, the birthplace of what we call techno music. Born in the darkest hour for our scene, deep in a global pandemic. A new star in the techno universe is illuminating the darkness. Driving basslines and beautiful melodies are shaping his unique style. Bringing back the sound of Frankfurt.
                    <br/><br/>
                    Safe the Rave!
                    <br/><br/><br/>
                    Disco Monarch
                </H3>
            </TextContainer>

    )
}

export default AboutMeText

export const TextContainer = styled.div`
    max-width: 600px;
    margin-left: 120px;
    text-align:left;
    @media (max-width: 768px) {
        margin-left:0;
        text-align:center;
      }
`

export const H3 = styled.h3`
    color: white;
    transition: 1.2s ease;
    &:hover {
        color: #00CCFF;
    } 
`

export const A = styled.a`
    color: white;
    transition: .5s ease;
    &:hover {
        color: #FF66FF;
    } 
`

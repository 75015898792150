import React, { useEffect, useState }  from 'react';
import styled from "styled-components";
import Logo from '../../assets/LogoPic.png'


type HeaderProps = {
    onRefClick: Function;
}



const Header: React.FC<HeaderProps> = ({onRefClick}) => {

    const [deviceWidthFlag, setDeviceWidthFlag] = useState<boolean>(false);

    useEffect(() => {
        setDeviceWidthFlag(window.matchMedia('(max-device-width: 960px)').matches)
    }, [deviceWidthFlag])

    const handleResize=()=>{
        setDeviceWidthFlag(window.matchMedia('(max-device-width: 960px)').matches)
    }

    window.addEventListener("resize", handleResize)

    return (
        <NavBar>
            {!deviceWidthFlag&&
            <LogoButton onClick={()=>onRefClick("intro")}><img src={Logo} width="90px" alt={"ICON"}/></LogoButton>}
            <NavBarButtons> 
                <ButtonBlue onClick={()=>onRefClick("news")}>NEWS</ButtonBlue>
                <ButtonViolette onClick={()=>onRefClick("about")}>ABOUT</ButtonViolette>
                <ButtonBlue onClick={()=>onRefClick("projects")}>DJ SETS</ButtonBlue>
                <ButtonViolette  onClick={()=>onRefClick("contact")}>CONTACT</ButtonViolette>              
            </NavBarButtons>
        </NavBar>
    )
}

export default Header

export const NavBar = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-evenly;
    position: sticky; 
    position: -webkit-sticky;
    height:auto;
    min-height:80px;
    top: 0;
    background-color: rgb(0,0,0);
    z-index:100;
`

export const NavBarButtons = styled.div`
    display: flex;
    flex-direction: row;
    text-align: center;
    height: 50px;
    min-height:50px;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 768px) {
        justify-content: space-around;
      }
`

export const LogoButton = styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    height: 80%;
    width: 90px;
`

export const ButtonViolette = styled.button`
font-family: 'Fahkwang', sans-serif;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    text-align: center;
    border: 3px solid white;
    color: white;
    height: 100%;
    margin: 0 3%;
    margin-top:1.5%;
    width: 90px;
    border-radius: 4px;
    transition-duration: 0.4s;
    &:hover {
        background-color: #FF66FF;
        border-color: #FF66FF;
        color: black;
    }
    @media (max-width: 768px) {
        margin-top:30px;
        width: auto;
      }
`

export const ButtonBlue = styled.button`
font-family: 'Fahkwang', sans-serif;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    text-align: center;
    border: 3px solid white;
    color: white;
    height: 100%;
    margin: 0 3%;
    margin-top:1.5%;
    width: 90px;
    border-radius: 4px;
    transition-duration: 0.4s;
    &:hover {
        background-color: #00CCFF;
        border-color: #00CCFF;
        color: black;
    }
    @media (max-width: 768px) {
        margin-top:30px;
        width: auto;
      }
`


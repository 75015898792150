import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faSpotify, faTwitter, faSoundcloud, faFacebook } from '@fortawesome/free-brands-svg-icons'
import {faEnvelope} from '@fortawesome/free-solid-svg-icons'
//https://fontawesome.com/icons?s=brands&m=free

const SocialMedia = () => {
    return (
            <SocialMediaContainer>
                <Link href="https://www.instagram.com/discomonarch/?hl=de">
                    <ButtonViolette>
                        <FontAwesomeIcon icon={faInstagram} size="4x"/>
                    </ButtonViolette>
                </Link>
                <Link href="https://soundcloud.com/discomonarch">
                    <ButtonOrange>
                        <FontAwesomeIcon icon={faSoundcloud} size="4x"/>
                    </ButtonOrange>
                </Link>
                <Link href="https://open.spotify.com/playlist/65F2H9i8rwKQWFxc1FVZ6h?si=IKyRbUUnR1-zVKCzkMTb1Q">
                    <ButtonGreen>
                        <FontAwesomeIcon icon={faSpotify} size="4x"/>
                    </ButtonGreen>
                </Link>
                <Link href="https://twitter.com/DiscoMonarch">
                    <ButtonBlue>
                        <FontAwesomeIcon icon={faTwitter} size="4x"/>
                    </ButtonBlue>
                </Link>
                <Link href="https://www.facebook.com/Disco-Monarch-108494137533724">
                    <ButtonFacebookBlue>
                        <FontAwesomeIcon icon={faFacebook} size="4x"/>
                    </ButtonFacebookBlue>
                </Link>
            </SocialMediaContainer>
    )
}

export default SocialMedia

export const SocialMediaContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom:5%;
    @media (max-width: 768px) {
        flex-direction: row;
        margin-top:2%;
        padding-top:3%
      }
`

export const Link = styled.a`
    margin:0 2%;
`

export const ButtonViolette = styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    text-align: center;
    border: 3px solid white;
    margin:0 2%;
    color: white;
    height: 90px;
    width: 90px;
    border-radius: 4px;
    transition: .5s ease;
    @media (max-width: 768px) {
        border: none;
      }
    &:hover {
        background-color: #FF66FF;
        color: black;
        border-color: #FF66FF;
    }
`

export const ButtonGreen = styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    text-align: center;
    border: 3px solid white;
    margin:0 2%;
    color: white;
    height: 90px;
    width: 90px;
    border-radius: 4px;
    transition: .5s ease;
    @media (max-width: 768px) {
        border: none;
      }
    &:hover {
        background-color: #99FF33;
        color: black;
        border-color: #99FF33;
    }
`

export const ButtonBlue = styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    text-align: center;
    border: 3px solid white;
    margin:0 2%;
    color: white;
    height: 90px;
    width: 90px;
    border-radius: 4px;
    transition: .5s ease;
    @media (max-width: 768px) {
        border: none;
      }
    &:hover {
        background-color: #1da1f2;
        color: black;
        border-color: #1da1f2;
    }
`

export const ButtonOrange = styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    text-align: center;
    border: 3px solid white;
    margin:0 2%;
    color: white;
    height: 90px;
    width: 90px;
    border-radius: 4px;
    transition: .5s ease;
    @media (max-width: 768px) {
        border: none;
      }
    &:hover {
        background-color: #ff7700;
        color: black;
        border-color: #ff7700;
    }
`

export const ButtonFacebookBlue = styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    text-align: center;
    border: 3px solid white;
    margin:0 2%;
    color: white;
    height: 90px;
    width: 90px;
    border-radius: 4px;
    transition: .5s ease;
    @media (max-width: 768px) {
        border: none;
      }
    &:hover {
        background-color: #4267B2;
        color: black;
        border-color: #4267B2;
    }
`
import React from 'react'
import styled from "styled-components";
import BigImage from '../../assets/nebulaCentered.jpg'
import SmallImage from '../../assets/nebulaMobile.jpg'
import SocialMedia from './SocialMedia';

const IntroContainer = () => {

    return (
        <Container>
            <LogoContainer>
            <Logo width="408" height="350" viewBox="0 0 408 350" fill="none" xmlns="http://www.w3.org/2000/svg">
                <LogoPath d="M330.309 78.4521L350.034 116.444L318.847 173.131L291.479 148.865L263.823 126.975L249.304 116.453L190.022 78.4521H330.309Z"/>
                <LogoPath d="M241.009 314.611H199.264L89.1699 116.453L104.673 86.4138L148.482 92.5464L170.118 116.453L222.729 150.107H187.43L218.542 207.56L242.603 164.739L270.622 186.687L297.715 211.541L241.009 314.611Z"/>
                <LogoPathStroke d="M330.309 78.4521L350.034 116.444L318.847 173.131L291.479 148.865M330.309 78.4521H190.022L249.304 116.453M330.309 78.4521L291.479 148.865M241.009 314.611H199.264L89.1699 116.453M241.009 314.611L148.482 150.107H222.729L170.118 116.453M241.009 314.611L297.715 211.541L270.622 186.687M89.1699 116.453L104.673 86.4138L148.482 92.5464L170.118 116.453M89.1699 116.453H170.118M187.43 150.107L218.542 207.56M218.542 207.56L238.642 244.678L270.622 186.687M218.542 207.56L242.603 164.739L270.622 186.687M249.304 116.453H269.736L263.823 126.975M249.304 116.453L263.823 126.975M263.823 126.975L291.479 148.865"/>
                <LogoPath d="M376.347 264.013L348.058 304.413C348.058 304.413 322.998 320.003 254.643 289.99L276.053 251.165C304.471 263.648 325.724 268.539 341.171 269.791C329.088 243.612 298.32 203.019 221.866 149.486C143.946 94.9248 94.8863 80.3604 66.3795 78.3979C70.7017 88.7889 77.9307 101.403 89.8182 116.108L151.572 228.815C20.4339 134.418 33.122 83.8926 33.122 83.8926L61.4106 43.4923C61.4106 43.4923 111.314 19.5801 245.676 113.661C380.037 207.742 376.347 264.013 376.347 264.013Z"/>
                <LogoPathStroke d="M348.058 304.413L376.347 264.013C376.347 264.013 380.037 207.742 245.676 113.661C111.314 19.5801 61.4106 43.4923 61.4106 43.4923L33.122 83.8926M348.058 304.413C348.058 304.413 351.676 292.549 341.171 269.791M348.058 304.413C348.058 304.413 322.998 320.003 254.643 289.99L276.053 251.165C304.471 263.648 325.724 268.539 341.171 269.791M33.122 83.8926C33.122 83.8926 42.6003 76.7609 66.3795 78.3979M33.122 83.8926C33.122 83.8926 20.4339 134.418 151.572 228.815L89.8182 116.108C77.9307 101.403 70.7016 88.7889 66.3795 78.3979M66.3795 78.3979C94.8863 80.3604 143.946 94.9248 221.866 149.486C298.32 203.019 329.088 243.612 341.171 269.791" />
            </Logo>
            </LogoContainer>
            <H1>DISCO MONARCH</H1>
            <SocialMedia/>
            {/*<WaveContainer>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#000000" fill-opacity="1" d="M0,64L14.1,80C28.2,96,56,128,85,122.7C112.9,117,141,75,169,74.7C197.6,75,226,117,254,117.3C282.4,117,311,75,339,48C367.1,21,395,11,424,5.3C451.8,0,480,0,508,26.7C536.5,53,565,107,593,117.3C621.2,128,649,96,678,74.7C705.9,53,734,43,762,42.7C790.6,43,819,53,847,80C875.3,107,904,149,932,181.3C960,213,988,235,1016,213.3C1044.7,192,1073,128,1101,122.7C1129.4,117,1158,171,1186,170.7C1214.1,171,1242,117,1271,96C1298.8,75,1327,85,1355,90.7C1383.5,96,1412,96,1426,96L1440,96L1440,320L1425.9,320C1411.8,320,1384,320,1355,320C1327.1,320,1299,320,1271,320C1242.4,320,1214,320,1186,320C1157.6,320,1129,320,1101,320C1072.9,320,1045,320,1016,320C988.2,320,960,320,932,320C903.5,320,875,320,847,320C818.8,320,791,320,762,320C734.1,320,706,320,678,320C649.4,320,621,320,593,320C564.7,320,536,320,508,320C480,320,452,320,424,320C395.3,320,367,320,339,320C310.6,320,282,320,254,320C225.9,320,198,320,169,320C141.2,320,113,320,85,320C56.5,320,28,320,14,320L0,320Z"></path></svg>
            </WaveContainer>*/}
        </Container>
    )
}

export default IntroContainer

export const Container = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100vh;
    justify-content: center;
    background: url(${BigImage}) no-repeat scroll;
    background-position:right top 70%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow-y:hidden;
    @media (max-width: 768px) {
        background-position:bottom;
        height:auto;
        background: url(${SmallImage}) no-repeat center scroll;
        background-position:right 50% top 40%;
      }
`

export const H1 = styled.h1`
    font-family: 'Fahkwang', sans-serif;
    font-size: 90px;
    color:white;
    text-align:center;
    transition: 1s ease;
    margin-top:-1%;
    &:hover {
        color: #00CCFF;
    } 
    } 
    @media (max-width: 768px) {
        font-size: 60px;
        margin-top:3%;
      }
`

export const LogoContainer = styled.div`
    display: flex;
    width:100%;
    height:auto;
    justify-content:center;
    @media (max-width: 768px) {
        margin-bottom:-6%;
      }
`

export const Logo = styled.svg`
@media (max-width: 768px) {
    width:200px;
    height:180px;
  }
`

export const LogoPath = styled.path`
    fill: black;    
`

export const LogoPathStroke = styled.path`
stroke: white;
stroke-width: 3px;
transition: 1s ease;
${Logo}:hover & {
    stroke: #FF66FF;
} 
`
import React from 'react'
import styled from 'styled-components'

const TwitterNews = () => {
    return (
        <Container>
            <TwitterContainer>
                <a className="twitter-timeline" data-theme="dark" href="https://twitter.com/DiscoMonarch?ref_src=twsrc%5Etfw" data-tweet-limit="3" data-chrome="transparent noheader nofooter">Tweets by DiscoMonarch</a>
            </TwitterContainer>
        </Container>
    )
}

export default TwitterNews

export const Container = styled.div`
    display:flex;
    justify-content: center;
    width:100%;
`

export const TwitterContainer = styled.div`
    width:1000px;
`
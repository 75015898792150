import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Header from '../components/Header/Header'
import Profile from '../components/ProfileSegment/ProfileContainer'
import Intro from '../components/Intro/IntroContainer'
import ProjectsContainer from '../components/Projects/ProjectsContainer'
import Form from '../components/ContactForm/Form'
import TwitterNews from '../components/News/TwitterNews'


const LandingPage = () => {

    const topRef = useRef<HTMLInputElement>(null);
    const profileRef = useRef<HTMLInputElement>(null);
    const projectsRef = useRef<HTMLInputElement>(null);
    const contactRef = useRef<HTMLInputElement>(null);
    const newsRef = useRef<HTMLInputElement>(null);
    const [cookiesFlag,setCookiesFlag] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        if (cookiesFlag){
            script.src = "https://platform.twitter.com/widgets.js";
            script.async = true;
            document.body.appendChild(script);
        }
      }, [cookiesFlag]);

    const onRefClick=(ref:string)=>{
        switch (ref) {
            case "intro" :
                if (Error && topRef.current!== null) {
                    topRef.current.scrollIntoView({behavior:"smooth"});
                    }
                break;
            case "news" :
                if (Error && newsRef.current!== null) {
                    newsRef.current.scrollIntoView({behavior:"smooth"});
                    }
                break;
            case "about" :
                if (Error && profileRef.current!== null) {
                    profileRef.current.scrollIntoView({behavior:"smooth"});
                    }
                break;
            case "projects" :
                if (Error && projectsRef.current!== null) {
                    projectsRef.current.scrollIntoView({behavior:"smooth"});
                    }
                break;
            case "contact" :
                if (Error && contactRef.current!== null) {
                    contactRef.current.scrollIntoView({behavior:"smooth"});
                    }
                break;
        }
    }

    return (
        <LandingPageContainer>
            <Header onRefClick={onRefClick}/>
            <ReferenceWrapper className="top" ref={topRef}>
                <Intro/>
            </ReferenceWrapper>
            <ReferenceWrapper className="news" ref={newsRef}>
                <H1>News</H1>
                {cookiesFlag? 
                <TwitterNews/>
                : 
                <CookieRequest>
                    <P>For displaying news you have to accept Twitter-Cookies. <A href="https://help.twitter.com/en/rules-and-policies/twitter-cookies" target="_blank" rel="noopener noreferrer">More Information here</A></P>
                    <ButtonBlue onClick={()=>setCookiesFlag(true)}>Accept</ButtonBlue>
                </CookieRequest>
                }
            </ReferenceWrapper>
            <ReferenceWrapper className="profile" ref={profileRef}>
                <Profile/>
            </ReferenceWrapper>
            <ReferenceWrapper className="projects" ref={projectsRef}>
                <ProjectsContainer/>
            </ReferenceWrapper>
            <ReferenceWrapper className="contact" ref={contactRef}>
                <Form/>
            </ReferenceWrapper> 
            </LandingPageContainer>
    )
}

export default LandingPage

export const LandingPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: auto;
    width: 100%;
    background-color:black;
`



export const ReferenceWrapper = styled.div`
`

export const CookieRequest = styled.div`
    display: flex;
    flex-direction:column;
    justify-content:center;
    text-align:center;
    align-items:center;
    margin-top:60px;
`

export const ButtonBlue = styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    text-align: center;
    border: 3px solid white;
    color: white;
    height: 50px;
    margin-top:1.5%;
    width: 90px;
    border-radius: 4px;
    transition-duration: 0.4s;
    &:hover {
        background-color: #00CCFF;
        border-color: #00CCFF;
        color: black;
    }
    @media (max-width: 768px) {
        margin-top:30px;
      }
`
export const P = styled.p`
      color:white;
`

export const LinkText = styled.p`
      color:#00CCFF;
      text-decoration: underline;
      cursor:pointer;
      &:hover {
        color:#FF66FF;
    }
`


export const H1 = styled.h1`
    font-family: 'Fahkwang', sans-serif;
    font-size: 60px;
    color:white;
    text-align:center;
    transition: 1s ease;
    padding-top:25px;
    &:hover {
        color: #00CCFF;
    } 
    } 
    @media (max-width: 768px) {
        font-size: 60px;
      }
`

export const A = styled.a`
    color:#00CCFF;
    &:hover {
        color:#FF66FF;
    }

`
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'



const Form = () => {

    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if ( window.location.search.includes('success=true') ) {
          setSuccess(true);
          window.scrollTo(0,document.body.scrollHeight);
        }
      }, []);

    return (
        <Container>
            <H1>Contact</H1>
            <FormContainer>
                <form name="contact" method="POST" action="/?success=true" data-netlify="true" >
                <input type="hidden" name="form-name" value="contact" />
                    <Input type="text" id="standard-basic"  name="name" placeholder="Name" />
                    <br /> <br />
                    <Input type="email" id="standard-basic"  name="email" placeholder="Email" /> <br />  <br />
                    <TextArea id="standard-multiline-static" name="message" placeholder="Enter your message..."/>
                    <p>
                        <SendButton type="submit">Send</SendButton>
                    </p>
                    {success && (
                        <Success>Thanks for your message! </Success>
                     )}
                </form>
            </FormContainer>
            
        </Container>

            )
        }

export default Form


export const Container = styled.div`
        display:flex;
        flex-direction: column;
        height:100vh;
        margin-top:10px;
        @media (max-width: 768px) {
            height:auto;
            margin-top:20px;
        }
`

export const FormContainer = styled.div`
        margin-top:30px;
        margin-left:20%;
        @media (max-width: 768px) {
            display:flex;
            justify-content:center;
            margin-left:0;
        }
`

export const Input = styled.input`
        color: white;
        background:none;
        border: 1px solid white;
        width:200px;
        height:30px;
        transition: .5s ease;
        border-radius: 5px;
        &:focus::-webkit-input-placeholder { color:transparent; }
        &:focus:-moz-placeholder { color:transparent; }
        &:focus::-moz-placeholder { color:transparent; } 
        &:focus:-ms-input-placeholder { color:transparent; } 

        &:hover{
            border: 1px solid #00CCFF;
        }
        @media (max-width: 768px) {
            text-align:center;
            width:90%;
          }
`

export const TextArea = styled.textarea`
        color: white;
        background:none;
        border: 1px solid white;
        width: 100%;
        height:300px;
        font-family: inherit;
        font-size: inherit;
        border-radius: 5px;
        max-width:70%;
        min-width:20%;
        max-height:400px;
        &:focus::-webkit-input-placeholder { color:transparent; }
        &:focus:-moz-placeholder { color:transparent; }
        &:focus::-moz-placeholder { color:transparent; } 
        &:focus:-ms-input-placeholder { color:transparent; } 
        &:focus{
            transition: .5s ease;
            border: 1px solid #FF66FF;
        }
        @media (max-width: 768px) {
            text-align:left;
            width:90%;
            max-width:none;
            resize:none;
          }
`

export const H1 = styled.h1`
    font-family: 'Fahkwang', sans-serif;
    font-size: 60px;
    color:white;
    text-align:center;
    transition: 1s ease;
    padding-top:20px;
    &:hover {
        color: #00CCFF;
    } 
    @media (max-width: 768px) {
        font-size: 60px;
      }
`

export const SendButton = styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    text-align: center;
    border: 3px solid white;
    color: white;
    height: 40px;
    width: 90px;
    border-radius: 4px;
    transition-duration: 0.4s;
    &:hover {
        background-color: #FF66FF;
        border-color: #FF66FF;
        color: black;
    }
    @media (max-width: 768px) {
        margin-top:10px;
      }
`

export const Success = styled.p`
        color: white;
        font-family: Impact;
        font-size: 20px;
        transition: .5s ease;
        &:hover{
            color: #00CCFF;
        }
`
import React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import track0 from '../../assets/track0.jpg'
import track01 from '../../assets/track01.jpg'
import track02 from '../../assets/track02.jpg'
import track04 from '../../assets/track04.jpg'


const Card = () => {

    return (
        <Container>
            <H1>DJ Sets</H1>
            <Tile>
                <TrackCard href="https://soundcloud.com/discomonarch/bassinjection-325nd-cuebase-fm" target="_blank" rel="noopener noreferrer">
                    <img src={track04} width="300px" height="300px"></img>
                </TrackCard>
                <TrackCard href="https://soundcloud.com/discomonarch/nye-minus-one" target="_blank" rel="noopener noreferrer">
                    <img src={track0} width="300px" height="300px"></img>
                </TrackCard>
            </Tile>
            
            <Tile>
                <TrackCard  href="https://soundcloud.com/discomonarch/welcome-to-the-tunnel" target="_blank" rel="noopener noreferrer">
                    <img src={track01} width="300px" height="300px"></img>
                </TrackCard>
                <TrackCard href="https://soundcloud.com/discomonarch/promo-set-mai-2020" target="_blank" rel="noopener noreferrer">
                    <img src={track02} width="300px" height="300px"></img>
                </TrackCard>
            </Tile>
            
        </Container>
    )
}

export default Card

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    min-height:100vh;
    justify-content:space-evenly;
    background-color:white;
`

export const Tile = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    justify-content:center;
`

export const TrackCard = styled.a`
    border:none;
    text-decoration: none;
    background:none;
    padding:none;
    margin: 10px;
    transition: .5s ease;
    cursor:pointer;
    &:hover {
        opacity:0.7;
    } 
    
`


export const H1 = styled.h1`
    font-family: 'Fahkwang', sans-serif;
    font-size: 60px;
    color:black;
    text-align:center;
    transition: 1s ease;
    &:hover {
        color: #00CCFF;
    } 
    } 
    @media (max-width: 768px) {
        font-size: 60px;
      }
`